<template>
  <div class="grid">
    <div class="field col-12 md:col-6">
      <h4><strong>Emergencias M&eacute;dicas</strong></h4>
    </div>
    <div class="field col-12 md:col-6 lg:text-right">
      <span class="text-2xl text-gray-800 font-bold">
        <i class="pi pi-money-bill"></i>
        Total Deuda: </span
      ><br />
      <span class="text-2xl text-red-600 font-bold">
        {{ convertirNumeroGermanicFormat(saldo_pendiente) }} Bs.
      </span>
    </div>
  </div>
  <div class="p-fluid grid formgrid style-card2 mb-2">
    <div class="field col-12 md:col-6">
      <span><strong>SELECCIONE PRODUCTO/SERVICIO:</strong></span>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-list"></i>
        </span>
        <AutoComplete
          v-model="productoSelected"
          :suggestions="productos"
          field="descripcion"
          v-tooltip.top="'Buscar Servicio'"
          @keyup.enter="buscarProducto"
          @item-select="agregarProducto"
        />
        <Button
          icon="pi pi-search"
          class="p-button-warning p-button-sm"
          v-tooltip.top="'Buscar Producto/Servicio'"
          @click="buscarProducto"
        />
      </div>
    </div>
    <div class="field col-12 md:col-3">
      <span><strong>M&Eacute;DICO:</strong></span>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <Dropdown
          v-model="data_datos_emergencias_medicas.medico_id"
          :options="medicos"
          optionLabel="nombre_completo"
          optionValue="id"
          placeholder="Seleccione..."
          v-tooltip.top="'Seleccione un Médico'"
        />
      </div>
    </div>
    <div class="field col-12 md:col-2">
      <span><strong>FECHA:</strong></span>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-calendar"></i>
        </span>
        <Calendar
          v-model="fecha"
          dateFormat="dd/mm/yy"
          v-tooltip.top="'Seleccione la fecha'"
        />
      </div>
    </div>
    <div class="field col-12 md:col-1">
      <span><strong>HORA:</strong></span>
      <Calendar
        v-model="hora"
        :timeOnly="true"
        hourFormat="12"
        v-tooltip.top="'Seleccione la hora'"
      />
    </div>
    <div class="field col-12 md:col-12">
      <DataTable
        :value="data_emergencias_medicas"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        editMode="cell"
        :rowhover="true"
        stripedRows
        showGridlines
        selectionMode="single click"
        v-model:expandedRows="expandedRows"
        @cell-edit-complete="saveChangeCell"
        :loading="cargando_emergencias_medicas"
      >
        <Column expander style="width: 1rem"></Column>
        <Column header="N°" style="width: 2rem">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column
          field="detalle"
          header="DETALLE/INTERVENCIÓN"
          style="font-weight: bold"
        >
          <template #body="slotProps">
            {{ slotProps.data.detalle }}
          </template>
        </Column>
        <Column
          field="cantidad"
          header="CANT."
          class="bg-green-200"
          style="text-align: right; font-weight: bold; width: 4rem"
        >
          <template #body="slotProps">
            {{ sinDecimal(slotProps.data.cantidad) }}
          </template>
          <template #editor="{ data, field }">
            <InputText
              :disabled="data.venta_id !== null && data.venta_id !== undefined"
              v-model="data[field]"
              :inputClass="'text-right'"
              class="p-inputtext-sm"
              v-tooltip.top="'Ingrese la cantidad'"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              :value="Number(data[field])"
            />
          </template>
        </Column>
        <Column
          field="costo"
          header="COSTO"
          style="text-align: right; font-weight: bold"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.costo ?? 0) }}
          </template>
        </Column>
        <Column
          field="acuenta"
          header="ACUENTA"
          style="text-align: right; font-weight: bold"
        >
          <template #body="slotProps">
            <span style="font-weight: bold; font-size: 1rem; color: green">{{
              convertirNumeroGermanicFormat(slotProps.data.acuenta ?? 0)
            }}</span>
          </template>
        </Column>
        <Column
          field="saldo"
          header="SALDO"
          style="text-align: right; font-weight: bold"
        >
          <template #body="slotProps">
            <span v-if="slotProps.data.saldo > 0" style="color: red">{{
              convertirNumeroGermanicFormat(slotProps.data.saldo)
            }}</span>

            <span v-else style="color: green">{{
              convertirNumeroGermanicFormat(slotProps.data.saldo)
            }}</span>
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="slotProps">
            <Badge v-if="slotProps.data.estado === 1" severity="warning"
              >PENDIENTE</Badge
            >
            <Badge v-if="slotProps.data.estado === 2" severity="blue"
              >PROCESO</Badge
            >
            <Badge v-if="slotProps.data.estado === 3" severity="success"
              >FINALIZADO</Badge
            >
            <Badge v-if="slotProps.data.estado === 0" severity="danger"
              >CANCELADO</Badge
            >
          </template>
          <template #editor="{ data, field }">
            <Dropdown
              v-model="data[field]"
              :options="estados"
              optionLabel="label"
              optionValue="value"
              class="p-inputtext-sm"
              v-tooltip.top="'Seleccione un estado'"
            >
              <template #option="{ option }">
                <Badge v-if="option.value === 1" severity="warning"
                  >PENDIENTE</Badge
                >
                <Badge v-if="option.value === 2" severity="orange"
                  >PROCESO</Badge
                >
                <Badge v-if="option.value === 3" severity="success"
                  >FINALIZADO</Badge
                >
                <Badge v-if="option.value === 0" severity="danger"
                  >CANCELADO</Badge
                >
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column field="fecha" header="FECHA">
          <template #body="slotProps">
            {{ slotProps.data.fecha ?? "" }}
          </template>
        </Column>
        <Column field="hora" header="HORA">
          <template #body="slotProps">
            {{ slotProps.data.hora ?? "" }}
          </template>
        </Column>
        <Column field="accion" header="ACCIONES">
          <template #body="slotProps">
            <div class="flex justify-center">
              <Button
                :disabled="slotProps.data.id == null"
                icon="pi pi-comments"
                class="p-button-sm p-button-info mr-1"
                v-tooltip.top="'Agregar Observación'"
                @click="activarComentario(slotProps.data.id)"
              />
              <Button
                :disabled="slotProps.data.acuenta > 0"
                icon="pi pi-trash"
                class="p-button-sm p-button-danger"
                v-tooltip.top="'Eliminar Registro'"
                @click="eliminarRegistro(slotProps.data.id)"
              />
            </div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div style="background-color: #ffffff">
            <DataTable
              :value="slotProps.data.detalles"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              stripedRows
              showGridlines
              selectionMode="single click"
              style="
                border: 1px solid #000000;
                border-radius: 2px;
                padding: 2px;
                box-shadow: 0px 0px 1px 0px #000000;
                font-size: 12px;
              "
            >
              <Column header="#">
                <template #body="slotProps">
                  {{ 1 + slotProps.index }}
                </template>
              </Column>
              <Column field="observacion" header="OBSERVACION"> </Column>
              <Column field="fecha" header="FECHA"> </Column>
              <Column field="hora" header="HORA"> </Column>
              <Column field="nombre_medico" header="REALIZADO POR"> </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
  <div class="grid">
    <div class="field col-12 md:col-12 lg:text-right">
      <Button
        icon="pi pi-refresh"
        class="p-button-success mr-1 p-button-lg"
        @click="cargarEmergenciasMedicas()"
        v-tooltip.top="'Recargar datos'"
        :loading="cargando_emergencias_medicas"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        @click="guardarEmergenciasMedicas"
        :disabled="data_emergencias_medicas.length === 0"
      />
    </div>
  </div>
  <AgregarComentario
    :show="comentarioDialog"
    :comentario="comentario"
    :medicos="medicos"
    @actualizarListado="cargarEmergenciasMedicas"
    @closeModal="cerrarComentarioDialog"
  />
</template>
<script>
import HistoriaService from "@/service/HistoriaService.js";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";
import AgregarComentario from "@/module/historias/AgregarComentario.vue";

export default {
  components: {
    AgregarComentario,
  },
  props: {
    datos_emergencias_medicas: {
      type: Object,
      default: () => {},
    },
    emergencias_medicas: {
      type: Array,
      default: () => {},
    },
    medicos: {
      type: Array,
      default: () => {},
    },
  },

  data() {
    return {
      data_datos_emergencias_medicas: this.datos_emergencias_medicas,
      loading: false,
      data_emergencias_medicas: this.emergencias_medicas,
      displayModal: false,
      productoSelected: null,
      productos: [
        { descripcion: "Producto 1" },
        { descripcion: "Producto 2" },
        { descripcion: "Producto 3" },
      ],
      expandedRows: [],
      estados: [
        { label: "PENDIENTE", value: 1 },
        { label: "PROCESO", value: 2 },
        { label: "FINALIZADO", value: 3 },
        { label: "CANCELADO", value: 0 },
      ],
      fecha: new Date(),
      //para hora que capture la hora actual
      hora: new Date().toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      }),
      cargando_emergencias_medicas: false,
      comentarioDialog: false,
      comentario: {},
    };
  },
  historiaService: null,
  productService: null,
  auth: null,

  created() {
    this.historiaService = new HistoriaService();
    this.productService = new ProductService();
    this.auth = useAuth();
  },
  computed: {
    saldo_pendiente() {
      let total = 0;
      this.data_emergencias_medicas.forEach((dato) => {
        total += parseFloat(dato.saldo);
      });
      return total;
    },
  },
  methods: {
    activarComentario(id) {
      this.comentarioDialog = true;
      this.comentario = {
        emergencia_id: id,
        cliente_id: this.data_datos_emergencias_medicas.cliente_id,
        nombre_cliente: this.data_datos_emergencias_medicas.nombre_cliente,
      };
    },
    cerrarComentarioDialog() {
      this.comentarioDialog = false;
    },
    guardarEmergenciasMedicas() {
      this.loading = true;
      let datos = {
        historia_id: this.data_datos_emergencias_medicas.historia_id,
        emergencias_medicas: this.data_emergencias_medicas || [],
        cliente_id: this.data_datos_emergencias_medicas.cliente_id,
        nombre_cliente: this.data_datos_emergencias_medicas.nombre_cliente,
        /* medico_id: this.data_datos_emergencias_medicas.medico_id,
        nombre_medico: this.data_datos_emergencias_medicas.nombre_medico, */
        medico_id: this.data_datos_emergencias_medicas.medico_id,
        nombre_medico:
          this.medicos.find(
            (medico) =>
              medico.id === this.data_datos_emergencias_medicas.medico_id
          ).nombre_completo || null,
      };
      this.historiaService
        .guardarEmergenciasMedicas(datos)
        .then((response) => {
          if (response.success === false) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail: response.message,
              life: 800,
            });
            return;
          }
          this.$toast.add({
            severity: "success",
            summary: "EXITO",
            detail: response.message,
            life: 800,
          });
          this.cargarEmergenciasMedicas();
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    cargarEmergenciasMedicas() {
      let datos = {
        cliente_id: this.data_datos_emergencias_medicas.cliente_id,
      };
      this.cargando_emergencias_medicas = true;
      this.historiaService
        .cargarEmergenciasMedicas(datos)
        .then((response) => {
          if (response.success == false) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail: response.message,
              life: 800,
            });
            this.cargando_emergencias_medicas = false;
            return;
          }
          this.data_emergencias_medicas = [...response.emergencias_medicas];
          this.cargando_emergencias_medicas = false;
        })
        .catch((error) => console.log(error));
    },
    saveChangeCell(e) {
      let { data, newValue, field } = e;
      data[field] = parseFloat(newValue);
      if (data[field] !== undefined && data[field] !== null) {
        if (field === "estado") {
          if (data.saldo > 0 && data[field] === 3) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail: "No puede finalizar un servicio con saldo pendiente",
              life: 800,
            });
            data.estado = 2;
            return;
          }
        }
        if (field === "cantidad") {
          data.saldo = data.costo * data.cantidad - data.acuenta;
        }
      }
    },
    buscarProducto() {
      setTimeout(() => {
        let datos = {
          texto: this.productoSelected,
        };
        this.productService
          .buscar_servicio(datos)
          .then((response) => {
            if (response.status == 400 || response.productos.length == 0) {
              this.$toast.add({
                severity: "error",
                summary: "ERROR",
                detail: "No se encontró el producto",
                life: 300,
              });
              return;
            }
            this.productos = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 200);
    },
    agregarProducto() {
      this.data_emergencias_medicas.push({
        id: null,
        producto_id: this.productoSelected.id,
        detalle: this.productoSelected.descripcion,
        cantidad: 1,
        costo: this.productoSelected.stock.length
          ? this.productoSelected.stock[0]["precio_venta"]
          : 0,
        descuento: 0,
        acuenta: 0,
        saldo: this.productoSelected.stock.length
          ? this.productoSelected.stock[0]["precio_venta"]
          : 0,
        fecha: this.formatFecha(this.fecha),
        hora: this.hora,
        estado: 1,
        observaciones: "",
        medicamentos: "",
        comision_medico: this.productoSelected.stock.length
          ? this.productoSelected.stock[0]["comision_medico"]
          : 0,
      });
      this.productoSelected = null;
    },
    formatFecha(value) {
      return new Date(value).toLocaleDateString("es-ES");
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return parseInt(numero);
    },
    modalConfirmacion() {
      this.displayModal = true;
    },
    cancelarEnvio() {
      this.displayModal = false;
      this.$toast.add({
        severity: "info",
        summary: "Información",
        detail: "Se cancelo el envio del Informe Ginecológico",
        life: 3000,
      });
    },
    eliminarRegistro(id) {
      if (id !== undefined) {
        this.$confirm.require({
          header: "CONFIRMACIÓN",
          message: "¿ESTÁS SEGURO DE ELIMINAR ESTE REGISTRO?",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "ELIMINAR",
          rejectLabel: "CANCELAR",
          acceptClass: "p-button-danger p-button-lg",
          rejectClass: "p-button-success p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          closable: false,
          closeOnEscape: false,
          accept: () => {
            this.historiaService
              .eliminarServicioEmergencia(id)
              .then((response) => {
                if (response.success === false) {
                  this.$toast.add({
                    severity: "error",
                    summary: "ERROR",
                    detail: response.message,
                    life: 800,
                  });
                  return;
                }
                this.$toast.add({
                  severity: "success",
                  summary: "EXITO",
                  detail: response.message,
                  life: 800,
                });
                this.cargarEmergenciasMedicas();
              })
              .catch((error) => console.log(error));
          },
        });
      } else {
        this.$confirm.require({
          header: "Confirmación",
          icon: "pi pi-exclamation-triangle",
          message: "¿ESTÁS SEGURO DE ELIMINAR ESTE REGISTRO?",
          acceptLabel: "Si, Eliminar",
          rejectLabel: "No, Cancelar",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          accept: () => {
            this.data_emergencias_medicas.splice(id, 1);
          },
        });
      }
    },
  },
  watch: {
    datos_emergencias_medicas(val) {
      this.data_datos_emergencias_medicas = val;
    },
    emergencias_medicas(val) {
      this.data_emergencias_medicas = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}
.p-float-label {
  .p-inputtextarea {
    color: #0062ff;
    text-transform: capitalize;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px dotted #00346b;
    &:focus {
      border-bottom: px solid #00346b;
    }
  }
}
.p-float-label label {
  color: #003a6e;
}
.p-divider {
  font-weight: bold;
  color: #003a6e;
}
.p-radiobutton-label {
  font-weight: bold;
  color: #003a6e;
}
.color-linea {
  border: 2px solid #003a6e;
  border-radius: 5px;
}
.titulo-izquierdo {
  font-weight: bold;
  color: #003a6e;
}
.texto-span {
  font-weight: bold;
  color: #003a6e;
}
.style-card {
  background-color: #f0f0f0;
  border: 1px solid #002236;
  border-radius: 5px;
  box-shadow: 0px 0px 1px 0px #002236;
}
.style-card2 {
  background-color: #fcfcfc;
  border: 1px solid #002236;
  border-radius: 10px;
}
</style>
