<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    :header="'NOTAS: ' + ' ' + data_comentario.nombre_cliente"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <span
            ><strong
              >REALIZADO POR: <small class="p-invalid">*</small></strong
            ></span
          >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <Dropdown
              v-model="medicoSelected"
              :options="medicos"
              optionLabel="nombre_completo"
              optionValue="id"
              placeholder="SELECCIONE UN MÉDICO"
              class="p-inputtext-sm mr-2"
              :filter="true"
              v-tooltip.top="'Seleccione un médico'"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>FECHA:</strong></span>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-calendar"></i>
            </span>
            <Calendar
              v-model="fecha"
              dateFormat="dd/mm/yy"
              v-tooltip.top="'Seleccione la fecha'"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>HORA:</strong></span>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-clock"></i>
            </span>
            <Calendar
              v-model="hora"
              :timeOnly="true"
              hourFormat="12"
              v-tooltip.top="'Seleccione la hora'"
            />
          </div>
        </div>
        <div class="field col-12 md:col-12">
          <span
            ><strong
              >NOTAS/OBSERVACI&Oacute;N:
              <small class="p-invalid">*</small></strong
            ></span
          >
          <Textarea v-model="data_comentario.observacion" rows="2" />
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarComentario"
        :loading="guardando"
      />
    </template>
  </Dialog>
</template>
<script>
import HistoriaService from "@/service/HistoriaService.js";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    comentario: {
      type: Object,
      default() {
        return {};
      },
    },
    medicos: {
      type: Array,
      default: () => [],
    },
  },
  historiaService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_comentario: this.comentario,
      guardando: false,
      fecha: new Date(),
      hora: new Date().toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      }),
      medicoSelected: null,
      listado_medicos: this.medicos,
    };
  },
  created() {
    this.historiaService = new HistoriaService();
  },
  methods: {
    ocultarDialog() {
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.guardando = false;
      this.medicoSelected = null;
      this.data_comentario = {};
    },
    guardarComentario() {
      this.guardando = true;
      if (!this.medicoSelected) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Seleccione un médico",
          life: 10000,
        });
        this.guardando = false;
        return;
      }
      if (!this.data_comentario.observacion) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Ingrese una observación",
          life: 10000,
        });
        this.guardando = false;
        return;
      }
      let datos = {
        ...this.data_comentario,
        medico_id: this.medicoSelected,
        nombre_medico: this.medicos.find(
          (item) => item.id == this.medicoSelected
        ).nombre_completo,
        fecha: this.fecha,
        hora: this.hora,
      };

      this.historiaService.guardarComentario(datos).then((data) => {
        if (data.success == false) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: data.message,
            life: 10000,
          });
          this.guardando = false;
        } else {
          this.$toast.add({
            severity: "success",
            summary: "Exito!",
            detail: data.message,
            life: 10000,
          });
          this.$emit("actualizarListado");
          this.ocultarDialog();
          this.guardando = false;
        }
      });
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    comentario(val) {
      this.data_comentario = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
